<template>
  <div class="address_edit">
    <van-sticky>
      <van-nav-bar
        title="编辑地址"
        left-text="返回"
        @click-left="onClickLeft"
        left-arrow
      />
    </van-sticky>
    <van-address-edit
      :area-list="areaList"
      show-postal
      show-delete
      show-set-default
      show-search-result
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
    />
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      areaList,
      searchResult: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSave() {
      this.$toast("save");
    },
    onDelete() {
      this.$toast("delete");
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: "黄龙万科中心",
            address: "杭州市西湖区",
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.address_edit {
  background: #f9f9f9;
  height: 100vh;
  .van-nav-bar /deep/ .van-icon,
  .van-nav-bar /deep/.van-nav-bar__text {
    color: #c03131;
  }
}
</style>
